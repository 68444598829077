<template>
  <div id="app">
    <NavBarView />
    <TitleView v-if="$route.name != 'login'"/>
    <router-view />
    <AppNotifyVue/>
    <FooterView />
  </div>
</template>


<script>
import NavBarView from "@/components/layouts/header/NavbarView.vue";
import TitleView from "@/components/layouts/header/TitleView.vue";
import AppNotifyVue from './components/AppNotify.vue'
import FooterView from "@/components/layouts/Footer/FooterView.vue";
export default {
  name: "AppHome",
  components: {
    NavBarView,
    TitleView,
    AppNotifyVue,
    FooterView,
  },
};
</script>

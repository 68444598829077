export default {
  state: {
    idDesignacionRevision: null,
  },
  mutations: {
    SET_idDesignacionRevision(state, idDeRev) {
      state.idDesignacionRevision = idDeRev;
    },
  },
  getters: {
    GET_idDesignacionRevision: (state) => state.idDesignacionRevision,
  },
};

export default {
   state: {
      minmenu: false,
   },
   mutations: {
      SET_SHOW_MENU(state) {
         state.minmenu = !state.minmenu
         if(state.minmenu){
            localStorage.setItem('sidebar', state.minmenu);
         }else{
            localStorage.removeItem('sidebar');
         }
      }
   },
   getters: {
      SET_SHOW_MENU: state => state.minmenu
   }
}
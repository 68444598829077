<template>
    <footer id="footer" class="footer">
        <div class="footer-top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="footer-info">
                            <h3>Escuela de Jueces del Estado</h3>
                            <p>
                                Ladislao Cabrera <br />
                                Nº 443, Sucre-Bolivia <br /><br />
                                <strong>Telefono :</strong> (591) 4-6425112<br />
                                <strong>Telefono 2 :</strong>(591) 4-6425110 <br />
                                <strong>Email:</strong> info@eje.gob.bo<br />
                            </p>
                            <div class="social-links mt-3">
                                <a href="https://www.facebook.com/eje.gob.bo/" target="_blank"><i
                                        class="bx bxl-facebook"></i></a>
                                <a href="https://www.youtube.com/user/ejebolivia" target="_blank"><i
                                        class="bx bxl-youtube"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 footer-newsletter">
                        <h4>Acerca de la Revista Jurídica Digital</h4>
                        <p class="justify-txt">
                            La EJE concibe como fundamental el desarrollo de la investigación en el ámbito jurídico dados los cambios constantes que existen en el Derecho Público y Privado, como producto de la evolución social del país y de la sociedad humana en general, por lo que se da también el continuo “nacimiento” de nuevas ramas del derecho que deben registrarse, estudiarse y valorarse.<br>
                            En este sentido, la Revista Jurídica de la Escuela de Jueces del Estado de Bolivia constituye el espacio propicio para la publicación del desarrollo de investigaciones y aportes de calidad.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="copyright">
                &copy; Escuela de Jueces del Estado
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: "FooterView",
};
</script>
export default {
   state: {
      item: null
   },
   mutations: {
      SET_ITEM_EDIT(state, item) {
         setTimeout(() => {
            state.item = item
         }, 30);
      },
      CLEAR_ITEM_EDIT(state) {
         state.item = null
      }
   },
   getters: {
      GET_ITEM_EDIT: state => state.item
   }
}
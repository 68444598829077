import { NAMEKEY } from "@/configs/urlsConfig";

export const TOKEN = localStorage.getItem(NAMEKEY);

export function outSesion() {
  localStorage.removeItem(NAMEKEY);
  location.reload();
}

export function setTokenSession(token) {
  localStorage.setItem(NAMEKEY, token);
  location.reload();
}

export default {
  state: {
    imgs_list: null,
    pdf_file: null,
  },
  mutations: {
    SET_PDF_VIEWER(state, imgs_list) {
      state.imgs_list = imgs_list;
    },
    SET_PDF_FILE(state, pdf_file) {
        state.pdf_file = pdf_file;
    },
  },
  getters: {
    GET_PDF_VIEWER: (state) => state.imgs_list,
    GET_PDF_FILE: (state) => state.pdf_file,
  },
};

<template>
  <div>
    <header id="header" class="header fixed-top">
      <div class="mx-4 d-flex align-items-center justify-content-between">
        <router-link :to="{ name: 'inicio' }" class="logo">
          <img src="@/assets/img/logo-full.png" alt="" class="img-fluid" />
        </router-link>

        <nav id="navbar" class="navbar">
          <ul>
            <li class="ocultamenu" v-show="!haySesion">
              <router-link :to="{ name: 'inicio' }" class="nav-link scrollto">
                Inicio
              </router-link>
            </li>
            <li>
              <a href="https://eje.gob.bo" class="nav-link scrollto">
                Sitio Web
              </a>
            </li>

            <li>
              <div v-show="!haySesion">
                <router-link
                  :to="{ name: 'login' }"
                  class="loginbutton scrollto"
                >
                  Iniciar Sesión
                </router-link>
              </div>
              <div v-show="haySesion">
                <a @click="cerrarSesion" class="loginbutton scrollto">
                  Cerrar Sesión</a
                >
              </div>
            </li>
          </ul>
          <i class="bi bi-border-width mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import { TOKEN, outSesion } from '@/shared/utils/session';
export default {
  name: 'NavBarView',
  data() {
    return {
      haySesion: null,
    };
  },
  methods: {
    cerrarSesion() {
      outSesion();
    },

    verificarSesion() {
      if (TOKEN) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.haySesion = this.verificarSesion();
  },
};
</script>

import Vue from "vue";
import Vuex from "vuex";
import config from "./modules/config";
import session from "./modules/session";
import itemEdit from "./modules/itemEdit";
import notify from "./modules/notify";
import pdf from "./modules/pdf";
import revisiones from "./modules/revisiones";
import nuevaConvReg from "./modules/nuevaConvReg";
import avisos from "./modules/avisos";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    session,
    config,
    itemEdit,
    notify,
    avisos,
    pdf,
    revisiones,
    nuevaConvReg,
  },
});

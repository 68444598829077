import AOS from "aos";
(function () {
  "use strict";

  window.addEventListener("load", () => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  });
})();
window.addEventListener("scroll", function () {
  let selectHeader = document.getElementById("header");
  if (selectHeader) {
    if (window.scrollY > 200) {
      selectHeader.classList.add("header-scrolled");
    } else {
      selectHeader.classList.remove("header-scrolled");
    }
  }
});
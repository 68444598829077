import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { apolloProvider } from "./configs/apolloConfig";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import VuePdfApp from "vue-pdf-app";
import vSelect from "vue-select";
// importaciones de icons
import { faFilePdf, faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { faPencil, faEye } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faFilePdf, faFileExcel, faPencil, faEye);
Vue.config.productionTip = false;
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("vue-pdf-app", VuePdfApp);
Vue.component("v-select", vSelect);
//importar estilos css
import "./configs/loaderCssStyles";

//cargar componentes de complementos
import VuePhoneNumberInput from "vue-phone-number-input";
import Vuelidate from "vuelidate";
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);

new Vue({
  apolloProvider,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

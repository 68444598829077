import Vue from "vue";
import VueRouter from "vue-router";
import { NAMEKEY } from "@/configs/urlsConfig";
Vue.use(VueRouter);

const routes = [

    {
        path: "/",
        name: "inicio",
        component: () => import("@/views/Home/HomeView.vue"),
    },
    {
        path: "/ver-aviso/:idAviso",
        name: "ver-aviso",
        component: () => import("@/views/Home/HomeView.vue"),
    },
    {
        path: "/ver-publicacion/:idConvocatoria",
        name: "ver-publicacion",
        component: () => import("@/views/Home/HomeView.vue"),
    },
    {
        path: "/revisiones/:idPostulacion",
        name: "revisiones",
        component: () => import("@/views/revisiones/"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/LoginView.vue"),
    },
    {
        path: "/recover_password/:token",
        name: "recover",
        component: () => import("@/views/recuperacionPassword/RecoverPassword.vue"),
    },
    {
        path: "/modificar",
        name: "modificar",
        component: () =>
            import("@/views/postulante/modificar/ModificarParticipante.vue"),
    },
    {
        path: "/recuperacion",
        name: "recuperacion",
        component: () => import("@/views/recuperacionPassword/"),
    },
    {
        path: "/avisos",
        name: "avisos",
        component: () => import("@/views/avisos/AllAvisos.vue"),
    },
    {
        path: "/perfil",
        name: "perfil",

        component: () => import("@/views/postulante/PerfilParticipante.vue"),
    },
    {
        path: "/registro/:idCategoria",
        name: "registro",
        component: () => import("@/views/login/RegistroLogin.vue"),
    },
    {
        path: "/categorias/:id",
        name: "categorias",
        component: () => import("@/views/Categorias/index.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    let key = localStorage.getItem(NAMEKEY);
    if (to.name == "inicio" && key) {
        next({ name: "perfil" });
    } else {
        if (to.name === "login" && key) {
            next({ name: "perfil" });
        } else {
            if (to.name == "registro" && key) {
                next({ name: "perfil" });
            } else {
                if (to.name === "perfil" && !key) {
                    next({ name: "inicio" });
                } else {
                    if (to.name === "revisiones" && !key) {
                        next({ name: "inicio" });
                    } else {
                        next();
                    }
                }
            }
        }
    }
});

export default router;

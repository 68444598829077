export default {
  state: {
    aviso: null,
  },
  mutations: {
    SET_AVISO_VIEWER(state, aviso) {
      state.aviso = aviso;
    },
  },
  getters: {
    GET_AVISO_VIEWER: (state) => state.aviso,
  },
};

export default {
  state: {
    idConvocatoria: null,
  },
  mutations: {
    SET_ID_CONVOCATORIA(state, id) {
      state.idConvocatoria = id;
    },
  },
  getters: {
    GET_ID_CONVOCATORIA: (state) => state.idConvocatoria,
  },
};

<template>
  <div class="titulo-container box-slider">
    <h1 class="title-shine">Revista Jurídica</h1>
  </div>
</template>
<script>
export default {
  name: "HeroView",
};
</script>

export const TYPE_NOTIFY = {
  error: "Error",
  info: "Información",
  success: "Correcto",
  warning: "Advertencia",
};

export const ROLES_ACCESO = [
  { value: "ADM", text: "Administración" },
  { value: "EVL", text: "Evaluador" },
  { value: "SUP", text: "Soporte" },
];

export const DIAS = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];
export const MESES = [
  "",
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
export const DEPARTAMENTOS = [
  { value: "CH", text: "Chuquisaca" },
  { value: "LP", text: "La Paz" },
  { value: "OR", text: "Oruro" },
  { value: "PT", text: "Potosi" },
  { value: "CB", text: "Cochabamba" },
  { value: "TJ", text: "Tarija" },
  { value: "BN", text: "Beni" },
  { value: "SC", text: "Santa Cruz" },
  { value: "PD", text: "Pando" },
];

export const DEPARTAMENTOS_TIPO = {
  CH: "Chuquisaca",
  LP: "La Paz",
  OR: "Oruro",
  PT: "Potosi",
  CB: "Cochabamba",
  TJ: "Tarija",
  BN: "Beni",
  SC: "Santa Cruz",
  PD: "Pando",
};
export const GENERO = [
  { value: "F", text: "Femenino" },
  { value: "M", text: "Masculino" },
  { value: "N", text: "No quiero Informar" },
  { value: "O", text: "Otro" },
];
export const GENERO_TIPO = {
  F: "Femenino",
  M: "Masculino",
  N: "No quiero Informar",
  O: "Otro",
};
export const GRADO_ACADEMICO = [
  { value: "PG", text: "Estudiante de Pre Grado" },
  { value: "LC", text: "Licenciatura" },
  { value: "MG", text: "Maestria" },
  { value: "DO", text: "Doctorado" },
  { value: "PD", text: "Post Doctorado" },
];
export const GRADO_ACADEMICO_TIPO = {
  PG: "Estudiante de Pre Grado",
  LC: "Licenciatura",
  MG: "Maestria",
  DO: "Doctorado",
  PD: "Post Doctorado",
};
export const PROFESION = [
  { value: "ES", text: "Estudiante" },
  { value: "AB", text: "Abogado/a" },
  { value: "SC", text: "Sociólogo/a" },
  { value: "AT", text: "Antropólogo/a" },
  { value: "PD", text: "Pedagogo/a" },
  { value: "TS", text: "Trabajador/a Social" },
  { value: "EC", text: "Economista" },
  { value: "PO", text: "Otro" },
];
export const PROFESION_TIPO = {
  ES: "Estudiante",
  AB: "Abogado/a",
  SC: "Sociólogo/a",
  AT: "Antropólogo/a",
  PD: "Pedagogo/a",
  TS: "Trabajador/a Social",
  EC: "Economista",
  PO: "Otro",
};

export const ETAPA_REVISION = {
  RDC: "Comité de redacción",
  CTF: "Comité científico",
  PAR: "Comité de pares temáticos",
  EDT: "Comité editorial",
};

export const ESTADO_REVISION = {
  PEN: "Revisión Pendiente",
  OBS: "Observado",
  DEN: "Rechazado",
  ACP: "Aceptado",
};
export const ESTADO_OBSERVACIONES = {
  PEN: "Pendiente",
  COR: "Corregida",
  SUB: "Subsanada",
};
export const ESTADO_DECISION = {
  ACP: "Aceptado",
  DEN: "Rechazado",
};
